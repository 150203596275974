<template>
  <div>
    <!-- Content Row -->
    <div class="vx-row">

      <div class="vx-col w-full">
        <vx-card :noShadow="true" :cardBorder="true">
          <div class="vx-row">
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="Nom Complet(*)" v-model="Nom_Complet" name="Nom complet" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full  mt-4" label="Nom Abregé (*)" v-model="Nom_Abrege" v-validate="'required'" name="Nom Abrege" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="N°IM" v-model="im" name="im" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="Code postale" v-model="adresse" name="adresse" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="Code client" v-model="codeClient" name="codeClient" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-select autocomplete v-model="pays" class="w-full select-large mt-4" label="Pays">
                <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item,index) in PaysData" class="w-full" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="Télephone" v-model="telephone" name="Telephone" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="Mobile" v-model="mobile"  name="Mobile" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="Email" v-model="Email" name="Email" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="Site internet" v-model="Site" name="Site" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" label="Compt Banquaire" v-model="ComptBanq" v-validate="'required|alpha_spaces'" name="compt Banquaire" />
            </div>
            <div class="vx-col md:w-1/2">
              <div class="mt-4 w-full">
                <label class="text-sm">Délais Règlement</label>
                <flat-pickr v-model="DelaiRegl" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DelaiRegl" />
              </div>
            </div>
            
          </div>
        </vx-card>
      </div>
    </div>
    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="addAssurance">Enregistrer</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Annuler</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'assurance-add',
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      Nom_Abrege :'',
      Nom_Complet :'',
      adresse :'',
      pays :'',
      telephone :'',
      mobile :'',
      fax :'',
      Email :'',
      Site :'',
      RegistreCom :'',
      ComptCont :'',
      ComptBanq :'',
      DelaiRegl : null,
      im: null,
      codeClient: null

    }
  },
  computed: {
    PaysData () {
      return this.$store.state.pays.pays
    }
  },
  methods: {
    addAssurance () {
      const payload = {
        Nom_Abrege: this.Nom_Abrege,
        Nom_Complet: this.Nom_Complet,
        adresse: this.adresse,
        pays: this.pays,
        telephone: this.telephone,
        mobile: this.mobile,
        fax        :this.fax,
        Email : this.Email,
        Site  : this.Site,
        RegistreCom: this.RegistreCom,
        ComptCont  : this.ComptCont,
        ComptBanq: this.ComptBanq,
        DelaiRegl: this.DelaiRegl,
        im: this.im,
        codeClient: this.codeClient
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('assurance/addAssurance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          this.reset_data()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data () {
      this.Nom_Abrege = ''
      this.Nom_Complet = ''
      this.adresse = ''
      this.pays = ''
      this.telephone = ''
      this.mobile = ''
      this.fax = ''
      this.Email = ''
      this.Site = ''
      this.RegistreCom = ''
      this.ComptCont = ''
      this.ComptBanq = ''
      this.DelaiRegl = ''
      this.im = null
      this.codeClient = null
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    }
  },
  created () {
    this.getPays()
  }
}
</script>

<style>
  .pos{
    margin: 10px;
  }
</style>
